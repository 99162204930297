html,
body {
  overflow-x: hidden;
  margin: 0;
  padding-top: 32px;
}

main {
  min-height: 100vh;
  min-height: 100dvb;
}
