html,
body {
  overflow-x: hidden;
  margin: 0;
  padding-top: 32px;
}

main {
  min-height: 100vh;
  min-height: 100dvb;
}


.styles_DialogOverlay__xFM7k {
  background-color: #808DB8;
  opacity: 0.8;
  position: fixed;
  inset: 0;
  animation: styles_overlayShow__TCBmU 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10;
}

.styles_DialogContent__qo8VI {
  background-color: transparent;
  border-radius: 6px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 75vh;
  max-width: 90vw;
  max-height: 217px;
  padding: 0px;
  animation: styles_contentShow__djhmn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 11;

  @media (min-width: 0px) {
    max-height: 60%;
  }

  @media (min-width: 992px) {
    width: 80vw;
    max-height: 85vh;
  }
}

.styles_DialogContent__qo8VI button {
  position: absolute;
  top: -72px;
  right: 0px;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;

  @media (min-width: 992px) {
    top: 0px;
    right: -64px;
  }
}

.styles_DialogContent__qo8VI video {
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.styles_DialogContent__qo8VI:focus {
  outline: none;
}

.styles_DialogTitle__z7RkB {
  margin: 0;
  font-weight: 500;
  font-size: 17px;
}

.styles_DialogDescription__mfjg4 {
  margin: 10px 0 20px;
  font-size: 15px;
  line-height: 1.5;
}

@keyframes styles_overlayShow__TCBmU {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes styles_contentShow__djhmn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@font-face {
font-family: '__LoftFont_ec83ba';
src: url(/_next/static/media/aeaa72d5deee7bf0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__LoftFont_ec83ba';
src: url(/_next/static/media/3f4113f04d96728c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__LoftFont_ec83ba';
src: url(/_next/static/media/6ddb8c9705d139ac-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__LoftFont_ec83ba';
src: url(/_next/static/media/0820956c5bbaa61c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__LoftFont_ec83ba';
src: url(/_next/static/media/95cc96956d7bbba5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__LoftFont_ec83ba';
src: url(/_next/static/media/207cbe15abe4a54f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__LoftFont_Fallback_ec83ba';src: local("Arial");ascent-override: 95.95%;descent-override: 24.29%;line-gap-override: 0.00%;size-adjust: 99.21%
}.__className_ec83ba {font-family: '__LoftFont_ec83ba', '__LoftFont_Fallback_ec83ba', Arial, sans-serif
}.__variable_ec83ba {--LoftFont: '__LoftFont_ec83ba', '__LoftFont_Fallback_ec83ba', Arial, sans-serif
}

